<template>
  <div id="contact">
    <div class="pc">
      <img src="../../assets/contact/banner.png" alt="" class="banner" />
      <div class="container">
        <h1>联系我们</h1>
        <div><img src="../../assets/home/line.png" alt="" class="" /></div>
        <p>即时在线，解答你的工作问题</p>
        <div class="content">
          <p>您有任何问题，请第一时间联系我们</p>
          <div class="box">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="60px"
              class="demo-ruleForm"
            >
              <el-form-item label="姓名" prop="name" style="width: 40%">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item
                label="电话"
                prop="phone"
                style="width: 40%"
                class="phone"
              >
                <el-input v-model="ruleForm.phone"></el-input>
              </el-form-item>
              <el-form-item label="留言" prop="desc" style="width: 45%">
                <el-input
                  type="textarea"
                  :rows="6"
                  v-model="ruleForm.desc"
                ></el-input>
              </el-form-item>
              <div class="sumbit">
                <el-button type="primary" @click="sumbit('ruleForm')"
                  >确认提交</el-button
                >
              </div>
            </el-form>
            <div class="info">
              <div class="info_top">
                <h3>联系信息</h3>
                <div class="round_left"></div>
                <div class="round_right"></div>
              </div>
              <div class="info_bottom">
                <p>商务合作热线：13547844133 / 18780038839</p>
                <p>公司邮箱: 2182466263@qq.com</p>
                <p>联系地址：四川省成都市金牛区蜀西路58号</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="baidumap" id="allmap"></div> -->
      </div>
    </div>
    <div class="mobile">
      <img src="../../assets/contact/banner.png" alt="" class="banner" />
      <div class="form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="name" style="width: 60%">
            <el-input v-model="ruleForm.name" size="mini"></el-input>
          </el-form-item>
          <el-form-item
            label="电话"
            prop="phone"
            style="width: 60%"
            class="phone"
          >
            <el-input v-model="ruleForm.phone" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="留言" prop="desc" style="width: 60%">
            <el-input
              type="textarea"
              :rows="4"
              v-model="ruleForm.desc"
              size="mini"
            ></el-input>
          </el-form-item>
          <div class="sumbit">
            <el-button type="primary" @click="sumbit" size="mini"
              >确认提交</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="info">
        <p>联系信息</p>
        <p>联系人：李老师/大老师</p>
        <p>联系电话：17702876961</p>
        <p>邮箱: 2182466263@qq.com</p>
      </div>
    </div>
  </div>
</template>

<script>
import { get_visit } from "@/api/all";

export default {
  data() {
    return {
      ruleForm: {
        name: "",
        phone: "",
        desc: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的名字", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入您的电话", trigger: "blur" },
          { min: 11, max: 11, message: "电话号码有误", trigger: "blur" },
        ],
        desc: [{ required: true, message: "留言不能为空", trigger: "blur" }],
      },
      position_NKYYGS: {
        Name: "四川省农业科学院",
        address: "成都市锦江区静居寺路20号",
        phoneNumber: "(028)84504011",
        bdLNG: 104.114026,
        bdLAT: 30.630106,
      },
    };
  },
  mounted() {
    // this.baiduMap();
  },

  methods: {
    baiduMap() {
      var map = new window.BMap.Map("allmap"); // 创建地图实例
      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      map.addControl(new window.BMap.NavigationControl());
      map.addControl(new window.BMap.ScaleControl());
      map.addControl(new window.BMap.OverviewMapControl());
      map.addControl(new window.BMap.MapTypeControl());
      // map.setMapStyle({ style: 'midnight' }) //地图风格

      map.clearOverlays();
      //将数据遍历
      //自定义信息窗口内容
      var sContent =
        '<div style="width:300px;"><p style="font-size:16px;font-weight:bold;margin-top: 10px;color:#D07852">' +
        this.position_NKYYGS.Name +
        '</p><p style="font-size:13px;margin: 5px 0;">地址：' +
        this.position_NKYYGS.address +
        '</p><p style="font-size:13px;margin: 5px 0;">电话：' +
        this.position_NKYYGS.phoneNumber +
        '</p><p style="font-size:13px;margin: 5px 0;">坐标：' +
        this.position_NKYYGS.bdLNG +
        "," +
        this.position_NKYYGS.bdLAT;
      ("</div>");
      var point = new window.BMap.Point(
        this.position_NKYYGS.bdLNG,
        this.position_NKYYGS.bdLAT
      ); // 创建点坐标
      var marker = new window.BMap.Marker(point);
      map.addOverlay(marker);
      map.centerAndZoom(point, 18); // 初始化地图，设置中心点坐标和地图级别
      // //点击图标时候调用信息窗口
      var infoWindow = new window.BMap.InfoWindow(sContent);
      marker.addEventListener("click", function () {
        this.openInfoWindow(infoWindow);
      });

      //   var map = new window.BMap.Map("allmap"); //创建地图

      //   map.enableScrollWheelZoom();

      //   var point = new window.BMap.Point(114.12883, 22.688615); //设置地图位置
      //   map.centerAndZoom(point, 12);
      //   var marker = new window.BMap.Marker(point); // 创建标注
      //   map.addOverlay(marker); // 将标注添加到地图中
    },
    sumbit(formName) {
      this.$refs[formName].validate((valid) => {
        //开启校验
        if (valid) {
          get_visit({
            name: this.ruleForm.name,
            phone: this.ruleForm.phone,
            content: this.ruleForm.desc,
          })
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("提交成功");
                this.ruleForm.name = "";
                this.ruleForm.phone = "";
                this.ruleForm.desc = "";
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //校验不通过
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#contact {
  .pc {
    .banner {
      width: 100%;
    }
    .container {
      width: 100%;
      height: 900px;
      h1 {
        font-size: 30px;
        padding: 3.5rem 0 1rem 0;
        color: rgba(13, 13, 13, 1);
      }
      p {
        font-size: 14px;
        color: rgba(112, 112, 112, 1);
      }
      .content {
        width: 70%;
        margin: auto;
        margin-top: 80px;
        p {
          text-align: left;
          padding-left: 20px;
          margin-bottom: 2.5rem;
        }
        .box {
          display: flex;
          .el-form {
            margin-right: 85px;
            .phone {
              margin: 3.75rem 0;
            }
            .el-input {
              width: 25.375rem;
            }
            .el-textarea {
              width: 36rem;
              // padding: 0 1rem;
            }
            .sumbit {
              margin-left: 60px;
              text-align: left;
              .el-button {
                width: 25.375rem;
                margin-top: 40px;
              }
            }
          }
          .info {
            width: 35.75rem;
            height: 28rem;
            background-color: skyblue;

            .info_top {
              width: 100%;
              height: 5.875rem;
              display: flex;
              align-items: center;
              // border-radius: 50%;
              border-bottom: 1px solid white;
              position: relative;
            }
            .round_left {
              width: 2.75rem;
              height: 2.75rem;
              background-color: white;
              border-radius: 50%;
              position: absolute;
              left: -(2.75rem/2);
              bottom: -(2.75rem/2);
            }
            .round_right {
              width: 2.75rem;
              height: 2.75rem;
              background-color: white;
              border-radius: 50%;
              position: absolute;
              right: -(2.75rem/2);
              bottom: -(2.75rem/2);
            }
            h3 {
              font-size: 1.875rem;
              color: white;
              text-indent: 1.875rem;
            }
            .info_bottom {
              margin-top: 30px;
              p {
                color: white;
                font-size: 1.25rem;
                text-indent: 1.875rem;
                text-align: left;
                padding: 0;
              }
            }
          }
        }
      }

      .baidumap {
        width: 1000px;
        height: 400px;
      }
    }
  }
  .mobile {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  #contact {
    .pc {
      display: none;
    }
    .mobile {
      display: block;
      .banner {
        width: 100%;
        display: flex;
      }
      .form {
        padding: 20px 0 15px 0;
        .el-form {
          .el-form-item {
            margin-bottom: 20px;
          }
        }
        .sumbit {
          display: flex;
          .el-button {
            width: 165px;
            margin-left: 60px;
          }
        }
      }
      .info {
        width: 100%;
        background-color: rgba(57, 139, 251, 1);
        color: white;
        font-size: 14px;
        padding: 20px 0;
        p {
          text-align: left;
          padding: 5px 0px 5px 15px;
        }
        p:nth-of-type(1) {
          width: 90px;
          padding: 5px 0;
          margin-left: 15px;
          border-bottom: 0.25px solid rgba(255, 255, 255, 1);
        }
      }
    }
  }
}
</style>
